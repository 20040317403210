import React from "react";
import { graphql } from "gatsby";
import LayoutComponent from "../components/global/layout";
import SEOComponent from "../components/global/seo";
import BlogIndexListingComponent from "../components/blog/post-index-listing";
const moment = require(`moment`);

const BlogListingDateLayout = ({ data, pageContext }) => {
  const { allMarkdownRemark } = data;
  
  let metaDescription = `Blog posts written in ${moment(pageContext.fullDateTime).format(pageContext.renderDateFormat)}.`;

  if (pageContext.currentPage > 1) {
    metaDescription = `Page ${pageContext.currentPage} of blog posts written in ${moment(pageContext.fullDateTime).format(pageContext.renderDateFormat)}.`;
  }

  return (
    <LayoutComponent>
      <SEOComponent title={`Posts Written In '${moment(pageContext.fullDateTime).format(pageContext.renderDateFormat)}'${(pageContext.currentPage > 1 ? ` - Page ${pageContext.currentPage}` : "")} | Blog`} 
                    metaDescription={metaDescription} 
                    shareImageIsLarge={true} />
      <div className="divide-y">
        <header className="pt-6 pb-8 space-y-2 md:space-y-5">
          <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
            Blog
          </h1>
          <p>{`Posts written in ${moment(pageContext.fullDateTime).format(pageContext.renderDateFormat)}.`}</p>
        </header>
        <BlogIndexListingComponent data={allMarkdownRemark} path={pageContext.pageLink} pageContext={pageContext} isFiltered={true} />
      </div>
    </LayoutComponent>
  )
}

export default BlogListingDateLayout

export const query = graphql`
  query blogPostsListByDate($skip: Int!, $limit: Int!, $fromDate: Date, $toDate: Date) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {draft: {eq: false}, date: {gte: $fromDate, lte: $toDate}}, fields: {type: {eq: "post"}}},
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            summary
            date
            categories {
              name
              slug
            }
            teaserImage
          }
          timeToRead
        }
      }
    }
  }`